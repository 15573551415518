// ** Initial State
const initialState = {
  tasks: [],
  selectedTask: {},
  params: {
    filter: "",
    q: "",
    sort: "",
    tag: "",
  },
};

const TodoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASKS":
      return { ...state, tasks: action.tasks, params: action.params };
    case "UPDATE_TASKS":
      return { ...state };
    case "REORDER_TASKS":
      return { ...state, tasks: action.tasks };
    case "SELECT_TASK":
      return { ...state, selectedTask: action.task };
    default:
      return state;
  }
};
export default TodoReducer;
